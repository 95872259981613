<!-- 解决方案 -->
<template>
  <div class="home_solution" v-once>
    <div class="title">我们提供定制化解决方案</div>
    <div class="solution">
      <div class="line" style="margin-bottom: 16px">
        <img :src="info[0]" style="width:520px" />
        <img :src="info[1]" style="width:399px; padding-left: 15px" />
        <img :src="info[2]" style="width:282px;padding-left: 15px" />
      </div>
      <div class="line">
        <img :src="info[3]" style="width:282px" />
        <img :src="info[4]" style="width:399px; padding-left: 15px" />
        <img :src="info[5]" style="width:520px;padding-left: 15px" />
      </div>
    </div>
  </div>
</template>

<script>
const info = [
  require("../../assets/home/s1.png"),
  require("../../assets/home/s2.png"),
  require("../../assets/home/s3.png"),
  require("../../assets/home/s4.png"),
  require("../../assets/home/s5.png"),
  require("../../assets/home/s6.png")
];
export default {
  name: "home_solution",
  components: {},
  data() {
    return {
      info: info
    };
  },
  created() {},
  mounted() {},
  methods: {}
};
</script>

<style lang="scss" scoped>
@import "../../common/scss/function.scss";
.home_solution {
  width: 100%;
  max-width: 1600px;
  .title {
    font-family: PingFangSC-Medium;
    font-size: 50px;
    font-weight: 600;
    color: #04101d;
    letter-spacing: 0;
    text-align: center;
    margin: 130px auto 82px auto;
  }
  .solution {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .line {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        height: 249px;
      }
    }
  }
}
</style>
