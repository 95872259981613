<!-- page -->
<template>
  <div class="PageWrap">
    <div class="bg">
      <img :src="src" height="100%" alt="" />
    </div>
    <div class="desc">
      <div v-for="(item, index) in info" :key="index">
        {{ item }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageWrap",
  props: {
    src: String,
    info: Array
  }
};
</script>

<style lang="scss" scoped>
.PageWrap {
  position: relative;
}
.bg {
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}
.desc {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}
.bg {
  z-index: 1;
  width: 100%;
  img {
    width: 100%;
  }
}
.desc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;

  background-image: linear-gradient(
    90deg,
    rgba(2, 6, 14, 0.6) 0%,
    rgba(3, 11, 26, 0.3) 100%
  );
  font-size: 40px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
  line-height: 60px;
  div {
    max-width: 1000px;
  }
}
</style>
