<!-- 我们的服务 -->
<template>
  <div class="home_service" v-once>
    <div class="title">我们的服务</div>
    <div class="services">
      <div class="item" v-for="(item, index) in info" :key="index">
        <img :src="item.icon" alt="" />
        <div class="title2">{{ item.title }}</div>
        <div class="desc">
          <div v-for="(txt, num) in item.desc" :key="num">
            {{ txt }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const info = [
  {
    icon: require("../../assets/home/service_0.png"),
    title: "VIP策划服务",
    desc: ["一对一需求沟通", "定制专属的推广方案"]
  },
  {
    icon: require("../../assets/home/service_1.png"),
    title: "专业的创意服务",
    desc: ["深度体验客户产品", "创作匹配的广告素材"]
  },
  {
    icon: require("../../assets/home/service_2.png"),
    title: "全方位的优化服务",
    desc: ["获取高质量用户", "让转化更透明高效"]
  },
  {
    icon: require("../../assets/home/service_3.png"),
    title: "数据分析服务",
    desc: ["每日跟进投放效果", "给客户专业的建议"]
  }
];
export default {
  name: "home_service",
  components: {},
  data() {
    return {
      info: info
    };
  },
  created() {},
  mounted() {},
  methods: {}
};
</script>

<style lang="scss" scoped>
@import "../../common/scss/function.scss";
.home_service {
  width: 100%;
  max-width: 1600px;

  .title {
    font-family: PingFangSC-Medium;
    font-size: 50px;
    font-weight: 600;
    color: #04101d;
    letter-spacing: 0;
    text-align: center;
    margin: 130px auto 82px auto;
  }
  .services {
    display: flex;
    align-items: center;
    justify-content: center;
    .item {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      img {
        width: 141px;
        height: 156px;
      }
      .title2 {
        margin: 38px auto 26px auto;
        font-weight: 500;
        margin-top: 37px;
        font-size: 38px;
        color: #3c3c3c;
      }
      .desc {
        font-weight: 500;
        font-size: 26px;
        color: #757575;
      }
    }
  }
}
</style>
