<!-- 我们的优势 -->
<template>
  <div class="home_advantage" v-once>
    <div class="title">我们的优势</div>
    <div class="advantages">
      <div class="line">
        <div
          class="item"
          v-for="(item, index) in info.slice(0, 3)"
          :key="index"
        >
          <div class="title2">{{ item.title }}</div>
          <div class="desc">
            <div v-for="(txt, num) in item.desc" :key="num">
              {{ txt }}
            </div>
          </div>
        </div>
      </div>
      <div class="line">
        <div
          class="item"
          v-for="(item, index) in info.slice(3, 6)"
          :key="index"
        >
          <div class="title2">{{ item.title }}</div>
          <div class="desc">
            <div v-for="(txt, num) in item.desc" :key="num">
              {{ txt }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const info = [
  {
    title: "一站式服务",
    desc: ["多年深耕互联网领域", "全方位快速整合资源"]
  },
  {
    title: "数据化投放",
    desc: ["深刻理解用户与市场", "有针对的获取客户"]
  },
  {
    title: "市场洞察",
    desc: ["梳理全球用户行为", "给客户最专业的建议"]
  },
  {
    title: "广告优化",
    desc: ["资深优化团队", "一对一提升账户转化"]
  },
  {
    title: "广告创意",
    desc: ["顶尖设计师", "原创设计"]
  },
  {
    title: "结果分析",
    desc: ["透明的投放结果", "大数据精准定位优化方向"]
  }
];
export default {
  name: "home_advantage",
  components: {},
  data() {
    return {
      info: info
    };
  },
  created() {},
  mounted() {},
  methods: {}
};
</script>

<style lang="scss" scoped>
@import "../../common/scss/function.scss";
.home_advantage {
  width: 100%;

  .title {
    font-family: PingFangSC-Medium;
    font-size: 50px;
    font-weight: 600;
    color: #04101d;
    letter-spacing: 0;
    text-align: center;
    margin: 130px auto 82px auto;
  }
  .advantages {
    background: rgba(2, 121, 255, 0.04);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .line {
      max-width: 1000px;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
    }
    .item {
      padding: 40px 60px;
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      img {
        width: 141px;
        height: 156px;
      }
      .title2 {
        margin: 38px auto 26px auto;
        font-weight: 500;
        margin-top: 37px;
        font-size: 20px;
        color: #3c3c3c;
      }
      .desc {
        font-weight: 500;
        font-size: 14px;
        color: #757575;
      }
    }
  }
}
</style>
