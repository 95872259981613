<template>
  <div class="home">
    <Page :src="src" :info="info"></Page>
    <Services></Services>
    <Solution></Solution>
    <Advantage></Advantage>
    <Footer></Footer>
  </div>
</template>

<script>
import Page from "../components/page";
import Services from "./home/serves";
import Solution from "./home/solution";
import Advantage from "./home/advantage";
import Footer from "./home/footer";

export default {
  name: "Home",
  data() {
    return {
      src: require("../assets/home.jpg"),
      info: ["移动广告服务代理", "提供创意，设计，技术，服务一站式解决方案"]
    };
  },
  components: { Page, Services, Solution, Advantage, Footer }
};
</script>
<style lang="scss" scoped>
.home {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
