<!-- home footer -->
<template>
  <div class="home_footer">
    <div class="colomn">
      <div>地址：中国（上海）自由贸易试验区世纪大道1777号6层B室</div>
      <div>联系电话：008613263266406</div>
    </div>
    <div class="colomn">
      <div>工作时间： 9am-9pm</div>
      <div>邮箱：brian@uamax.net</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "home_footer",
  components: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {}
};
</script>

<style lang="scss" scoped>
@import "../../common/scss/function.scss";

.home_footer {
  width: 100%;
  margin-top: 157px;
  height: 340px;
  background: rgba(2, 121, 255, 0.04);
  font-size: 18px;
  color: #333333;
  line-height: 16px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  .colomn {
    width: 600px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    div:last-child {
      padding-top: 40px;
    }
  }
}
</style>
