<template>
  <div id="app">
    <div class="nav">
      <Nav></Nav>
    </div>
    <transition>
      <router-view />
    </transition>
  </div>
</template>
<script>
import Nav from "./components/nav";
export default {
  name: "app",
  components: { Nav }
};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #ffffff;
  /* width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden; */
  background: #eeeeee;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #ffffff;
}

#nav a.router-link-exact-active {
  color: #ffffff;
}
.nav {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}
</style>
