import Vue from "vue";
import App from "./App.vue";
// import "./common/js/rem";
import "./common/scss/reset.scss";
import router from "./router";

Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
